<template>
    <v-card color="#f7f7f7">
        <template v-if="loading">
            <v-col cols="12" class="text-center" pa-5 ma-5>
            <v-progress-circular
                :width="3"
                color="primary"
                indeterminate
            ></v-progress-circular>
            </v-col>
        </template>
        <template v-else>
            <template v-if="changed">
                <v-col cols="12" class="text-center" pa-5 ma-5>
                    Order information has been changed. Refresh data to see updated info.
                </v-col>
            </template>
            <template v-else>
                <SquareReceipt :item=order item-key="name" ref="receipt"
                    v-bind:order=order
                    v-bind:showTitle="true"
                    v-bind:showDetails="true"
                    :showCode=false
                />

                <v-col cols="12">
                    <v-card-actions>
                        <v-btn v-show="allowChangePickup" outlined color="primary" @click="showPickupDialog = true" :loading="loading" :disabled="loading">
                            Change Pickup
                        </v-btn>
                        <v-btn v-show="allowCompletePickup" outlined color="primary" @click="showPickupDialog = true" :loading="loading" :disabled="loading">
                            Complete Order
                        </v-btn>
                        <v-btn outlined color="primary" @click="completeSquareOrder" :loading="loading" :disabled="loading">
                            Complete Square only
                        </v-btn>
                        <v-btn v-show="allowDeletePickup" outlined color="warning" @click="askToConfirmDeletePickup = true" :loading="loading" :disabled="loading">
                            Delete {{delivered ? "Delivery": "Pickup"}}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn v-show="allowRefund" outlined color="error" @click="askToConfirmRefund = true" :loading="loading" :disabled="loading">
                            Issue Refund
                        </v-btn>

                    </v-card-actions>
                </v-col>
                <v-col v-if="refundError" cols="12">
                    <v-alert v-model="refundError" type="error" dismissible >
                        Error processing refund.
                    </v-alert>
                </v-col>

                <v-dialog v-model="showLocationDialog" max-width="420">
                    <v-card>
                        <v-card-title primary-title>
                            <div>
                            <div class="headline">{{order.name}} (x{{order.line_items[0].quantity}})</div>
                            <span>{{ order.line_items[0].variation_name}}</span>
                            </div>
                        </v-card-title>
                        <v-col cols="12" class="text-center" ma-3>

                            <div slot="label" class="text-left mb-n3"><strong>Fulfillment type:</strong></div>
                            <v-radio-group v-model="fulfillmentSelected">
                                <v-radio
                                v-for="(item) in fulfillments"
                                :key=item
                                :label=item
                                :value=item
                            ></v-radio>
                            </v-radio-group>

                            <div slot="label" class="text-left mb-n3"><strong>{{fulfillmentByLabel}}</strong></div>
                            <v-radio-group v-model="fulfillmentBySelected">
                                <v-radio
                                v-for="(item, i) in fulfillmentBy"
                                :key=i
                                :label=item
                                :value=i
                            ></v-radio>
                            </v-radio-group>
                            
                            </v-col>
                        <v-card-actions class="justify-left">
                            <v-btn outlined color="light" @click="showPickupDialog=false" v-show="!loading">
                            <v-icon>close</v-icon>&nbsp;Cancel</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn outlined color="primary" @click="completePickup" :loading="loading">
                            <v-icon>check</v-icon>&nbsp;Complete Order</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="showPickupDialog" max-width="420">
                    <v-card>
                        <v-card-title primary-title>
                            <div>
                            <div class="headline">{{order.name}} (x{{order.line_items[0].quantity}})</div>
                            <span>{{ order.line_items[0].variation_name}}</span>
                            </div>
                        </v-card-title>
                        <v-col cols="12" class="text-center" ma-3>

                            <div slot="label" class="text-left mb-n3"><strong>Requested Fulfillment:</strong></div>
                            <v-radio-group v-model="fulfillmentSelected">
                                <v-radio
                                v-for="(item) in fulfillments"
                                :key=item.location
                                :label="titleCase(item.type)+' @ '+item.location"
                                :value=item
                            ></v-radio>
                            </v-radio-group>

                            <div slot="label" class="text-left mb-n3"><strong>Status:</strong></div>
                            <v-radio-group v-model="fulfillmentSelected">
                                <v-radio
                                v-for="(status) in statuses"
                                :key=status
                                :label=status
                                :value=status
                            ></v-radio>
                            </v-radio-group>

                            <div slot="label" class="text-left mb-n3"><strong>{{fulfillmentByLabel}}</strong></div>
                            <v-radio-group v-model="fulfillmentBySelected">
                                <v-radio
                                v-for="(item, i) in fulfillmentBy"
                                :key=i
                                :label=item
                                :value=i
                            ></v-radio>
                            </v-radio-group>
                            
                            </v-col>
                        <v-card-actions class="justify-left">
                            <v-btn outlined color="light" @click="showPickupDialog=false" v-show="!loading">
                            <v-icon>close</v-icon>&nbsp;Cancel</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn outlined color="primary" @click="completePickup" :loading="loading">
                            <v-icon>check</v-icon>&nbsp;Complete Order</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                
                <v-dialog v-model="askToConfirmDeletePickup" max-width="290">
                    <v-card>
                        <v-card-title class="headline">Confirmation</v-card-title>
                        <v-card-text>
                        Remove logged {{delivered ? "delivery": "pickup"}}? This will make the order eligible to pickup the full order. This action is recorded.
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" outlined @click="deletePickup">Delete {{delivered ? "Delivery": "Pickup"}}</v-btn>
                            <v-btn color="light" outlined @click="askToConfirmDeletePickup = false">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="askToConfirmRefund" max-width="340">
                    <v-card>
                        <v-card-title class="headline">Confirm Refund</v-card-title>
                        <v-subheader>Refund ${{ (order.total_money.amount/100).toFixed(2) + " to " + member.fullName + "?" }}</v-subheader>
                        <v-col ma-4>
                            <v-combobox
                            v-model="selectedRefundReason"
                            :items="refundReasons"
                            label="Select or enter refund reason"
                            hint="This reason is sent by email to customer" persistent-hint
                            ></v-combobox>
                        </v-col>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" outlined text :disabled="selectedRefundReason==''" @click="refund">Issue Refund</v-btn>
                            <v-btn color="light" outlined text @click="askToConfirmRefund = false">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </template>
        </template>
    </v-card>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import api from '@/store/api'
import SquareReceipt from '@/components/payment/SquareReceipt'
import moment from 'moment'
import AllMixins from '@/mixins/AllMixins'
export default {
    components: {
        SquareReceipt
    },
    data () {
        return {
            askToConfirmRefund: false,
            askToConfirmCompletePickup: false,
            askToConfirmDeletePickup: false,
            headers: [
                { text: 'Item', align: 'left', value: 'name'},
                { text: 'Price', value: 'price', align: 'center'},
                { text: 'Quantity', value: 'selected', align: 'center'},
            ],
            refundReasons: [
                "Duplicate or accidental order",
                "Order pickup window expired",
                "Customer cancelled order",
                "Processing error requires refund and re-purchase",
            ],
            selectedRefundReason: '',
            refundError: false,
            loading: false,
            changed: false,
            showPickupDialog: false,
          //  fulfillments: ["Pickup", "Delivery"],
            fulfillmentSelected: "Pickup",
            fulfillmentBySelected: "",
            showLocationDialog: false,
            product: null,
            fulfillments: [],
            statuses: ["Ordered", "Completed"],
        }
    },
    props: ['order', 'member'],
    mixins: [AllMixins],
    mounted(){
        this.getProductForOrder()
    },
    computed: {
        allowDeletePickup () {
            return !this.refunded && (this.pickedUp || this.delivered)
        },
        allowCompletePickup () {
            return !this.refunded && !this.pickedUp && !this.delivered
        },
        allowRefund () {
            if(this.order.payment.missingRecord)
                return false
            return !this.refunded
        },
        pickedUp () {
            return this.order.pickedUp != undefined && this.order.pickedUp != ""
        },
        delivered () {
            return this.order.deliveryInitiated != undefined && this.order.deliveredTo != ""
        },
        refunded () {
            return this.order.status == "Refunded"
        },
        fulfillmentByLabel () {
            if(this.fulfillmentSelected == "Delivery")
                return "Sent for delivery to:"
            else
                return "Picked up by:"
        },
        fulfillmentBy () {
            //console.log(this.member)
            let options = [this.member.fullName + " (Member #" + this.member.memberId + ")"]
            if(this.member.proxy)
                options.push(this.member.proxy + " (Proxy)")
            return options
        }
    },
    methods: {
        ...mapGetters([
            'userInfo',
        ]),
        async getProductForOrder(){
            this.loading = true
            const record = await FIREBASE.db.collection("products").doc(this.order.productId).get()
            this.product = record.data()
            this.fulfillments = this.product.fulfillments
           // console.log(this.product)
            this.loading = false
        },
        completePickup () {
            let customer = this.member.fullName
            if(this.fulfillmentBySelected == 1)
                customer = this.member.proxy
            if(customer == undefined)
                customer = "admin test"
            let employee = this.userInfo().fullName
            let fulfillment = this.fulfillmentSelected
            let delivery = (fulfillment == "Delivery")
            let ts = moment().tz("America/Phoenix").format("YYYY-MM-DD HH:mm")
            this.showPickupDialog = false
            this.loading = true
            FIREBASE.db.collection("orders").doc(this.order.id)
            .update({
                status: "Completed",
                pickupFulfilledBy: employee,
                pickedUp: delivery ? "": ts,
                pickedUpBy: delivery ? "": customer,
                deliveryInitiated: delivery ? ts: "",
                deliveredTo: delivery ? customer: ""
            })
            .then( () => {
                this.completeSquareOrder()
                this.loading = false
                this.order.status = "Completed"
                this.$emit('order-updated', this.order)
            })
            .catch( error => {
                console.log(error)
            })
        },
        async completeSquareOrder () {
            var functionsCall = global.FIREBASE.functions.httpsCallable('completeSquareOrderFulfillment')
            let response = await functionsCall({ order: this.order })
            return response
        },
        deletePickup () {
            this.askToConfirmDeletePickup = false
            this.loading = true
            let employee = this.userInfo().fullName
            var now = moment().tz("America/Phoenix").format("YYYY-MM-DD HH:mm")
            FIREBASE.db.collection("orders").doc(this.order.id)
            .update({
                pickedUpBy: "",
                pickedUp: "",
                deliveryInitiated: "",
                deliveredTo: "",
                status: "Ordered",
                pickupDeleted: now,
                pickupDeletedBy: employee
            })
            .then( () => {
                this.loading = false
                this.$emit('order-updated', this.order)
            })
            .catch( error => {
                console.log(error)
            })
        },
        async refund () {
            this.askToConfirmRefund = false
            this.loading = true
            var functionsCall = global.FIREBASE.functions.httpsCallable('processOrderRefund')
            let result = await functionsCall(
                {
                    uid: FIREBASE.auth.currentUser.uid, 
                    name: this.userInfo.fullName, 
                    order: this.order, 
                    reason: this.selectedRefundReason
                }
            )
            this.loading = false
            if(result.data.status == "success"){
                this.refundError = false
                this.order.status = "Refunded"
                this.$emit('order-updated', this.order)
            }
            else {
                this.refundError = true
                console.log("Error refunding order: " + result.data.error)
            }
        },

    }
}
</script>